import "bootstrap/dist/css/bootstrap.min.css";
import MainRoutes from "./Routes/index";
import React from "react";
// import Lorder from './common/Lorder';

function App() {
  return (
    <>
      <MainRoutes />
    </>
  );
}
export default App;
