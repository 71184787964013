import { fetch } from "../fetch.services";
import { ADMIN_API_PATH, ADMIN_API_PATH_BUSSINESS } from "../../constant/path";
// import { json } from "react-router-dom";
// import { AUTH_TOKEN } from "@mui/icons-material";
// import { AUTH_TOKEN } from '../../constant/token'

// let getToken = localStorage.getItem("AUTH_TOKEN")
// let AUTH_TOKEN = "AUTH_TOKEN " + getToken
// console.warn(AUTH_TOKEN)

export const getRegistrationForm = (options) => {
  return fetch("post", ADMIN_API_PATH + "/register/", JSON.stringify(options), {
    "Content-Type": "application/json",
    Accept: "application/json",
  });
};
export const getUserDetails = (options) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/api/get-user/",
    JSON.stringify(options),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  );
};
export const getUserData = (options) => {
  return fetch(
    "post",
    ADMIN_API_PATH_BUSSINESS + "/bussiness_email/",
    JSON.stringify(options),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  );
};

// -----------------------------Delete-Account/Recover-Account--------------------------------

export const DeleteAccount = (options) => {
  return fetch("delete", ADMIN_API_PATH + `/api/deletion/?${options}`, {});
};

export const RecoverAccount = (options) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/api/restore/",
    JSON.stringify(options),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  );
};
// -----------------------------Login Sign-up--------------------------------

export const GenerateToken = (options) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/api/authenticate/",
    JSON.stringify(options),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  );
};
export const LoginResendOtp = (options) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/api/resend-otp/",
    JSON.stringify(options),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  );
};
export const SignUpResendOtp = (options) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/api/resend-otp/",
    JSON.stringify(options),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};
export const VerifyUser = (options) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/api/otp-verify/",
    JSON.stringify(options),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  );
};
export const NewUserRegister = (options) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/api/user-register/",
    JSON.stringify(options),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};

// -------------------Career------------------------------------

export const postCareer = (form_data) => {
  return fetch("post", ADMIN_API_PATH + "/career/", form_data, {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  });
};
// -------------------NewOpportunity------------------------------------

export const NewOpportunity = () => {
  return fetch("get", ADMIN_API_PATH + "/notification/", {}, {});
};

// -----------------------------getArticle--------------------------------

export const getArticle = (category) => {
  return fetch(
    "get",
    ADMIN_API_PATH + `/posts/articles?category=${category}`,
    {},
    {}
  );
};
export const getAllArticle = () => {
  return fetch("get", ADMIN_API_PATH + "/posts/articles", {}, {});
};

// -----------------------------opportunities--------------------------------

export const GetPreCommitStatus = (id) => {
  return fetch("get", ADMIN_API_PATH + "/pre-commit/?casepool=" + id, {}, {});
};

export const preCommit = (option) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/pre-commit/",
    JSON.stringify(option),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};
export const GetReturnTax = (option) => {
  return fetch("post", ADMIN_API_PATH + "/return/", JSON.stringify(option), {
    "Content-Type": "application/json",
    Accept: "application/json",
    // Authorization:AUTH_TOKEN
  });
};

export const DownloadReturnPDF = (option, type) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/json-pdf/?param=" + type,
    JSON.stringify(option),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};

export const casePool = () => {
  return fetch("get", ADMIN_API_PATH + "/casepool/", {}, {});
};
export const casePoolClosed = () => {
  return fetch("get", ADMIN_API_PATH + "/casepool/closed/", {}, {});
};
export const casePoolDetails = (id) => {
  return fetch("get", ADMIN_API_PATH + "/pool?id=" + id, {}, {});
};

// -----------------------------Return-Pdf/--------------------------------
export const ReturnPdf = (options) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/return-pdf/",
    JSON.stringify(options),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};
// -----------------------------User_KYC --------------------------------

export const ProfileUpdate = (type, options) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/api/user-update/?param=" + type,
    JSON.stringify(options),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};
export const PanFileUpload = (option) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/ekyc/PAN/Files/",
    JSON.stringify(option),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};
export const aadharFileUpload = (option) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/ekyc/Aadhar/Files/",
    JSON.stringify(option),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};
export const DocumentPdf = (id, type) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/ekyc/download/?document_id=" + id,
    JSON.stringify(type),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};
export const BankDetails = (option) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/ekyc/bank_account/",
    JSON.stringify(option),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};
export const DematDetails = (option) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/ekyc/demat/",
    JSON.stringify(option),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};

export const DeleteDocument = (option) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/ekyc/delete-docs/",
    JSON.stringify(option),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};

export const UpdateUserName = () => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/ekyc/update_profile/?update_name",
    JSON.stringify(),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};

export const getEsignRequest = () => {
  return fetch("get", ADMIN_API_PATH + "/ekyc/esign_request", {}, {});
};

// -----------------------------Portfolio--------------------------------

export const investorPortfolio = () => {
  return fetch("get", ADMIN_API_PATH + "/investor-portfolio/", {}, {});
};

export const getOrderPaymentStatus = (option) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/payment-details/",
    JSON.stringify(option),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};

export const investorCasePoolDetails = (id) => {
  return fetch(
    "get",
    ADMIN_API_PATH + "/opportunity-details/?casepool_id=" + id,
    {},
    {}
  );
};

export const getPendingEsign = () => {
  return fetch("get", ADMIN_API_PATH + "/pending-esign-agreements", {}, {});
};

export const documentsESign = (id) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/E-sign/aggrement/?investor=" + id,
    {},
    {}
  );
};
export const documentDownload = (id, type) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/ekyc/download/?document_id=" + id,
    JSON.stringify(type),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};

// -----------------------------Refer--------------------------------
export const getReferral = () => {
  return fetch("get", ADMIN_API_PATH + "/refer-earn", {}, {});
};

export const makeRedeem = (option) => {
  return fetch("post", ADMIN_API_PATH + "/payouts/", JSON.stringify(option), {
    "Content-Type": "application/json",
    Accept: "application/json",
    // Authorization:AUTH_TOKEN
  });
};

export const ReferInvitation = (option) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/refer-invitation/",
    JSON.stringify(option),
    {
      "Content-Type": "application/json",
      // Authorization: REFER_AUTH_TOKEN,
      Accept: "application/json",
    }
  );
};

// -----------------------------Payment_Api--------------------------------

export const CreateOrder = (option) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/create-payment-order/",
    JSON.stringify(option),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  );
};

export const orderData = (option) => {
  return fetch(
    "post",
    ADMIN_API_PATH + "/order-data/",
    JSON.stringify(option),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization:AUTH_TOKEN
    }
  );
};
