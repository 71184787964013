import React, { useState, useEffect } from "react";
import { IMAGE_PATH, DOMAIN_PATH } from "../constant/path";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function LoginNav() {
  const navigate = useNavigate();

  // const Redirection = useNavigate();

  // const goBack = () => {
  //   navigate("#");
  // };

  // const goBack = ()

  return (
    <>
      <nav className="navbarPublic loginNav">
        <div className="nav-container ">
          <Link to="/">
            <i onClick={""} class="fa fa-angle-left" aria-hidden="true"></i>
            <img src={IMAGE_PATH + "Legalpay_black_logo.png"} />{" "}
          </Link>
          <ul className="nav-menu1 flex_wrapper">
            {/* <li className="nav-item">
              <button className="helpButton">
                <Link to="/">Home</Link>
              </button>
            </li> */}
            <li className="nav-item">
              <button className="helpButton">
                <a
                  href=" mailto:investor@legalpay.in?subject=LegalPay_Investor%20Query?body=Hi%20LegalPay%20Team,%20I%20have%20a%20question%20and%20would%20like%20to%20get%20in%20touch%20with%20you."
                  target="blank"
                >
                  Help
                </a>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
