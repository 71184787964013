import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import configureRoutes from "./routes";
import PrivateLayout from "./private-layout";
import PublicLayout from "./public-layout";
import LoginLayout from "./login-layout";
import ErrorLayout from "./error-Layout";
import ScrollToTop from "../components/ScrollToTop";
import { createBrowserHistory } from "history";

const MainRoutes = () => {
  const routes = configureRoutes();
  // console.log(routes)
  const history = createBrowserHistory();

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {routes.map((route) => {
            if (route.type === "private") {
              return (
                <Route
                  path={route.path}
                  element={<PrivateLayout {...route} />}
                />
              );
            }
            if (route.type === "public") {
              return (
                <Route
                  path={route.path}
                  element={<PublicLayout {...route} />}
                />
              );
            }
            if (route.type === "login") {
              return (
                <Route path={route.path} element={<LoginLayout {...route} />} />
              );
            }
          })}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default MainRoutes;
