import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { getUserDetails } from "../services/api/api.service";
import { useSelector } from "react-redux";
import { IMAGE_PATH, DOMAIN_PATH } from "../constant/path";
import ReactGA from "react-ga4";
import { color } from "@mui/system";
function NavBar() {
  const [click, setClick] = useState(false);
  // const [ShowMore, setShowMore] = useState(false);
  const [dashboard, setDashboard] = useState(false);

  const handleClick = () => {
    setClick(!click);
    // setShowMore(false);
  };
  const usertoken = useSelector((state) => state.userToken.authenticate[0]);
  useEffect(() => {
    let userToken = {
      token: usertoken,
    };
    if (usertoken !== undefined) {
      getUserDetails(userToken)
        .then((res) => {
          ReactGA.event("header", { action: "Header" });
          if (res.data[0].is_user_active === true) {
            setDashboard(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  return (
    <>
      <nav className="navbarPublic">
        <div className="nav-container">
          <Link to="/">
            <img className="nav-logo" src={IMAGE_PATH + "Logo_nav.png"} />{" "}
          </Link>
          <ul
            className={click ? "nav-menu active" : "nav-menu"}
            style={{ alignItems: "center" }}
          >
            <li className="nav-item">
              <NavLink
                exact
                to="/get-funding"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                {/* Get Funding */}
                Finance your Legal Expenses
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/Investors/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Investor
              </NavLink>
            </li>

            <li className="nav-item " id="dropdownMenu">
              <NavLink
                exact
                // to="Dummy"
                // activeClassName="active1"
                className="nav-links "
                style={{ margin: "auto", color: "#fff" }}
                // onClick={(e) => {
                //   setShowMore(!ShowMore);
                //   e.preventDefault();
                // }}
              >
                More <i class="fa fa-angle-down" aria-hidden="true"></i>
              </NavLink>
              {/* {ShowMore ? ( */}
              <div id="MoreOpt">
                <ul>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/about"
                      activeClassName="active"
                      className="nav-links"
                      onClick={handleClick}
                    >
                      About
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/faqs"
                      activeClassName="active"
                      className="nav-links"
                      onClick={handleClick}
                    >
                      FAQs
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/blogs"
                      activeClassName="active"
                      className="nav-links"
                      onClick={handleClick}
                    >
                      Insights
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/career"
                      activeClassName="active"
                      className="nav-links"
                      onClick={handleClick}
                    >
                      Careers
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/* ) : null} */}
            </li>
            {dashboard ? (
              <li className="nav-item">
                <NavLink
                  exact
                  to="/investor-opportunities/"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  View Dashboard
                </NavLink>
              </li>
            ) : (
              <li className="nav-item">
                <NavLink
                  exact
                  to="/investor-login-n1-int/"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Login/Signup
                </NavLink>
              </li>
            )}
          </ul>

          <div className="nav-icon" onClick={handleClick}>
            {click ? (
              <i class="fa fa-times" aria-hidden="true"></i>
            ) : (
              <i class="fa fa-bars" aria-hidden="true"></i>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
