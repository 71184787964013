import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IMAGE_PATH, DOMAIN_PATH } from "../constant/path";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { getUserDetails } from "../services/api/api.service";
import { useDispatch } from "react-redux";
import { GET_USER } from "../Redux/Actions/Actions";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import OutsideClickHandler from "react-outside-click-handler";
import ReactGA from "react-ga4";
export default function Navbar() {
  const [Data, setData] = useState({
    firstName: "",
    LastName: "",
  });

  const [ShowMore, setShowMore] = useState(true);
  const [KYCStatus, setKYCStatus] = useState(0);
  const [notificationMsg, setNotificationMsg] = useState({
    head: "",
    msg: "",
    link: "",
  });
  const [KYCStatusType, setKYCStatusType] = useState();
  const [notificationPop, setNotificationPop] = useState(false);
  const Dispatch = useDispatch();

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const usertoken = useSelector((state) => state.userToken.authenticate[0]);
  // console.log("token", usertoken);

  let path = window.location.href;

  // let Change = window.onclick();

  // useEffect(() => {
  //   setShowMore(!ShowMore);
  // }, [Change]);

  useEffect(() => {
    if (ShowMore) {
      setNotificationPop(false);
      // setShowMore(true)
    }
    if (notificationPop) {
      setShowMore(false);
      // setNotificationPop(true);
    }
  }, [ShowMore, notificationPop]);

  useEffect(() => {
    let userToken = {
      token: usertoken,
    };
    getUserDetails(userToken)
      .then((res) => {
        ReactGA.event("navbar", { action: "Navbar" });
        let KYC_Status = res.data[0].user_pan;
        Dispatch(GET_USER(res.data[0]));
        if (
          res.data[0].is_kyc_verified === false ||
          res.data[0].kyc_state === null
        ) {
          setNotificationMsg({
            head: "KYC is pending",
            msg: "Kindly complete your KYC verification. Just 4 simple steps will get you started with exciting investment opportunities.",
            link: `/investor-profile/profile-kyc/${"process"}`,
          });
          setKYCStatus(1);
        }
        KYC_Status?.map((status) => {
          if (
            res.data[0].is_kyc_verified === false ||
            res.data[0].kyc_state === null
          ) {
            setNotificationMsg({
              head: "KYC is pending",
              msg: "Kindly complete your KYC verification. Just 4 simple steps will get you started with exciting investment opportunities.",
              link: `/investor-profile/profile-kyc/${"process"}`,
            });
            setKYCStatus(1);
          } else if (
            res.data[0].kyc_state === "Demat" &&
            status.is_cmr_verified === null
            //   ||
            // status.is_cmr_verified === "pending"
          ) {
            setKYCStatus(1);
            setNotificationMsg({
              head: " Verification under process...!",
              msg: "  Your KYC verification is under process. In the meanwhile, explore our exciting opportunities.",
              link: "",
            });
          } else if (
            res.data[0].kyc_state === "bank" &&
            status.is_cmr_verified === "false"
          ) {
            setKYCStatus(1);
            setNotificationMsg({
              head: "Oops! Your KYC failed.",
              msg: " Oops! Your KYC failed.Please upload your correct CMR/CML report to invest in bonds.",
              link: `/investor-profile/profile-kyc/${"process"}`,
            });
          } else if (
            res.data[0].is_kyc_verified === true &&
            res.data[0].kyc_state !== "Demat"
          ) {
            setNotificationMsg({
              head: "Congratulations!",
              msg: "   Your KYC is completed however you can also invest in Super-Senior Bonds just by your CMR/CML report.",
              link: `/investor-profile/profile-kyc/${"process"}`,
            });
            setKYCStatus(1);
          }
        });

        setData({
          firstName: res.data[0].first_name,
          LastName: res.data[0].last_name,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [path]);

  if (notificationPop) {
    document.querySelector("body,html").addEventListener(
      "click",
      function () {
        setNotificationPop(false);
      },
      true
    );
    document.querySelector("body,html").addEventListener(
      "touch",
      function () {
        setNotificationPop(false);
      },
      true
    );

    window.addEventListener("wheel", function () {
      setNotificationPop(false);
    });
  }

  // document.body.addEventListener("click", handleClickTab);

  useEffect(() => {
    let userToken = {
      token: usertoken,
    };
    getUserDetails(userToken)
      .then((res) => {
        Dispatch(GET_USER(res.data[0]));
        // if (res.data[0].kyc_state === "Demat") {
        //   // setKYCStatus(0);
        // }
        // // if(res.data[0])
        setData({
          firstName: res.data[0].first_name,
          LastName: res.data[0].last_name,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [path]);

  useEffect(() => {
    setShowMore(false);
  }, [path]);

  useEffect(() => {
    setNotificationPop(false);
  }, [path]);

  const navigate = useNavigate();

  const logOut = () => {
    navigate("/");
    localStorage.clear();
    window.location.reload();
  };

  return (
    <>
      <nav className="navbarPublic">
        <div className="nav-container main_div_kyc">
          <Link to="/">
            <img className="nav-logo" src={IMAGE_PATH + "Logo_nav.png"} />{" "}
          </Link>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/investor-opportunities/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Opportunities
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/investor-portfolio/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Portfolio
              </NavLink>
            </li>
            <li className="nav-item refer_navbtn">
              <NavLink
                exact
                to="/refer-earn/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Refer & Earn
              </NavLink>
              <p>
                <span>
                  <i class="fa fa-inr" aria-hidden="true"></i>
                </span>{" "}
                1,000
              </p>
            </li>
            <li className="nav-item KYC_Notification KYC_Notification_mobile">
              <NavLink
                exact
                to="#"
                activeClassName=""
                className="nav-links"
                onClick={(e) => {
                  setNotificationPop(true);
                  e.preventDefault();
                }}
              >
                <Badge
                  badgeContent={KYCStatus}
                  color="error"
                  className="notification"
                >
                  <NotificationsIcon />
                </Badge>
              </NavLink>

              {notificationPop ? (
                <div id="MoreOpt1">
                  <ul>
                    <li className="nav-item1">Notifications</li>
                    {KYCStatus === 1 ? (
                      <li className="nav-item1 nav-item_bg ">
                        <div className="kyc_pending_dorp">
                          <p>
                            <i class="fa fa-user" aria-hidden="true"></i>
                          </p>
                          <h4>
                            {notificationMsg.head}
                            <br />
                            <span class="username_profile1">
                              {notificationMsg.msg}
                            </span>
                            <br />
                          </h4>
                        </div>
                        <ul>
                          {notificationMsg.link ? (
                            <li>
                              <p>
                                <Link to={notificationMsg.link}>
                                  Complete KYC
                                  <i
                                    class="fa fa-angle-double-right"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              </p>
                            </li>
                          ) : null}
                        </ul>
                      </li>
                    ) : (
                      <div>
                        <h4 className="dataError Text_Center">
                          No Notifications !
                        </h4>
                      </div>
                    )}
                  </ul>
                </div>
              ) : null}
            </li>

            <li className="nav-item " id="dropdownMenu">
              <NavLink
                exact
                to="/investor-profile/"
                activeClassName="active"
                className="nav-links dropdown_hide"
                onClick={(e) => {
                  setShowMore(!ShowMore);
                  e.preventDefault();
                }}
              >
                <span className="userProfile">
                  {usertoken === undefined || usertoken === null ? (
                    <>
                      <i
                        style={{ color: "#e95050" }}
                        class="fa fa-exclamation"
                        aria-hidden="true"
                      ></i>{" "}
                    </>
                  ) : (
                    <>
                      {Data.firstName?.charAt(0).toUpperCase()}
                      {Data.LastName?.charAt(0).toUpperCase()}
                    </>
                  )}
                </span>
                <i
                  class="fa fa-angle-down"
                  id="profile_Dropdown"
                  aria-hidden="true"
                ></i>
              </NavLink>
              {/* {ShowMore ? ( */}
              <div id="MoreOpt">
                <ul>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/investor-profile/"
                      activeClassName="active"
                      className="nav-links"
                      // onClick={handleClick}
                    >
                      Profile
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/investor-faqs"
                      activeClassName="active"
                      className="nav-links "
                      onClick={handleClick}
                    >
                      FAQs
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    {usertoken === undefined || usertoken === null ? (
                      <NavLink
                        exact
                        to="/investor-login-n1-int/"
                        activeClassName="active"
                        className="nav-links"
                      >
                        Login
                      </NavLink>
                    ) : (
                      <NavLink
                        exact
                        to="/"
                        activeClassName="active"
                        className="nav-links"
                        onClick={() => {
                          logOut();
                        }}
                      >
                        Logout
                      </NavLink>
                    )}
                  </li>
                </ul>
              </div>
              {/* ) : null} */}
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/investor-profile/"
                activeClassName="active"
                className="nav-links hide_profile"
                onClick={handleClick}
              >
                Profile
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/investor-faqs"
                activeClassName="active"
                className="nav-links hide_profile"
                onClick={handleClick}
              >
                FAQs
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/dummy"
                activeClassName="active"
                className="nav-links hide_profile"
                onClick={() => {
                  logOut();
                }}
              >
                Log Out
              </NavLink>
            </li>
          </ul>
          {/* <div className="nav-icon" onClick={handleClick}>
            {click ? (
              <li className="nav-item KYC_Notification">
                <NavLink
                  exact
                  to="#"
                  activeClassName=""
                  className="nav-links"
                  onClick={(e) => {
                    setNotificationPop(!notificationPop);
                    e.preventDefault();
                  }}
                >
                  <Badge
                    badgeContent={KYCStatus}
                    color="error"
                    className="notification"
                  >
                    <NotificationsIcon />
                  </Badge>
                </NavLink>

                {notificationPop ? (
                  <div id="MoreOpt1">
                    <ul>
                      <li className="nav-item1">Notifications</li>
                      {KYCStatus == 1 ? (
                        <li className="nav-item1 nav-item_bg ">
                          <div className="kyc_pending_dorp">
                            <p>
                              <i class="fa fa-user" aria-hidden="true"></i>
                            </p>
                            <h4>
                              {notificationMsg.head}
                              <br />
                              <span class="username_profile1">
                                {notificationMsg.msg}
                              </span>
                              <br />
                            </h4>
                          </div>
                          <ul>
                            {notificationMsg.link ? (
                              <li>
                                <p>
                                  <Link to={notificationMsg.link}>
                                    Complete KYC
                                    <i
                                      class="fa fa-angle-double-right"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                </p>
                              </li>
                            ) : null}
                          </ul>
                        </li>
                      ) : (
                        <div>
                          <h4 className="dataError Text_Center">
                            No Notifications !
                          </h4>
                        </div>
                      )}
                    </ul>
                  </div>
                ) : null}
              </li>
            ) : (
              <li className="nav-item KYC_Notification">
                <NavLink
                  exact
                  to="#"
                  activeClassName=""
                  className="nav-links"
                  onClick={(e) => {
                    setNotificationPop(!notificationPop);
                    e.preventDefault();
                  }}
                >
                  <Badge
                    badgeContent={KYCStatus}
                    color="error"
                    className="notification"
                  >
                    <NotificationsIcon />
                  </Badge>
                </NavLink>

                {notificationPop ? (
                  <div id="MoreOpt1">
                    <ul>
                      <li className="nav-item1">Notifications</li>
                      {KYCStatus == 1 ? (
                        <li className="nav-item1 nav-item_bg ">
                          <div className="kyc_pending_dorp">
                            <p>
                              <i class="fa fa-user" aria-hidden="true"></i>
                            </p>
                            <h4>
                              {notificationMsg.head}
                              <br />
                              <span class="username_profile1">
                                {notificationMsg.msg}
                              </span>
                              <br />
                            </h4>
                          </div>
                          <ul>
                            {notificationMsg.link ? (
                              <li>
                                <p>
                                  <Link to={notificationMsg.link}>
                                    Complete KYC
                                    <i
                                      class="fa fa-angle-double-right"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                </p>
                              </li>
                            ) : null}
                          </ul>
                        </li>
                      ) : (
                        <div>
                          <h4 className="dataError Text_Center">
                            No Notifications !
                          </h4>
                        </div>
                      )}
                    </ul>
                  </div>
                ) : null}
              </li>
            )}
          </div> */}
          <ul className="kyc_notifiaction_list ">
            <li className="nav-item KYC_Notification KYC_Notification_desktop">
              <NavLink
                exact
                to="#"
                activeClassName=""
                className="nav-links"
                onClick={(e) => {
                  setNotificationPop(!notificationPop);
                  e.preventDefault();
                }}
              >
                <Badge
                  badgeContent={KYCStatus}
                  color="error"
                  className="notification"
                >
                  <NotificationsIcon />
                </Badge>
              </NavLink>

              {notificationPop ? (
                <div id="MoreOpt1">
                  <ul>
                    <li className="nav-item1">Notifications</li>
                    {KYCStatus === 1 ? (
                      <li className="nav-item1 nav-item_bg ">
                        <div className="kyc_pending_dorp">
                          <p>
                            <i class="fa fa-user" aria-hidden="true"></i>
                          </p>
                          <h4>
                            {notificationMsg.head}
                            <br />
                            <span class="username_profile1">
                              {notificationMsg.msg}
                            </span>
                            <br />
                          </h4>
                        </div>
                        <ul>
                          {notificationMsg.link ? (
                            <li>
                              <p>
                                <Link to={notificationMsg.link}>
                                  Complete KYC
                                  <i
                                    class="fa fa-angle-double-right"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              </p>
                            </li>
                          ) : null}
                        </ul>
                      </li>
                    ) : (
                      <div>
                        <h4 className="dataError Text_Center">
                          No Notifications !
                        </h4>
                      </div>
                    )}
                  </ul>
                </div>
              ) : null}
            </li>

            <li className="nav-item userProfile_drop" id="dropdownMenu">
              <NavLink
                exact
                to="/investor-profile/"
                activeClassName="active"
                className="nav-links dropdown_hide"
                onClick={(e) => {
                  setShowMore(!ShowMore);
                  e.preventDefault();
                }}
              >
                <span className="userProfile">
                  {usertoken === undefined || usertoken === null ? (
                    <>
                      <i
                        style={{ color: "#e95050" }}
                        class="fa fa-exclamation"
                        aria-hidden="true"
                      ></i>{" "}
                    </>
                  ) : (
                    <>
                      {Data.firstName?.charAt(0).toUpperCase()}
                      {Data.LastName?.charAt(0).toUpperCase()}
                    </>
                  )}
                  {/* {Data.firstName.charAt(0).toUpperCase()}
                  {Data.LastName.charAt(0).toUpperCase()} */}
                </span>{" "}
              </NavLink>
              {/* {ShowMore ? ( */}

              {/* ) : null} */}
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
