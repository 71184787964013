import React from 'react'
import loadingGif from '../assets/Logo.gif'


export default function SuspenseLoader() {
  return (
    <div className="loader_cover">
      <img src={loadingGif} alt="" />
    </div>
  )
}
