import { createStore } from "redux";
import rootReducer from './Redux/Reducer/main'
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";



const persistConfig = {
    key: "Persist-store",
    storage
};
const PersistReducer = persistReducer(persistConfig,rootReducer)

const store = createStore(PersistReducer);

 
export const persistor = persistStore(store)
export default store; 