const INIT_STATE0 = {
  authenticate: [],
};
export const userToken = (state = INIT_STATE0, action) => {
  switch (action.type) {
    case "TOKEN":
      return {
        authenticate: [action.payload],
      };
    default:
      return state;
  };

};
const INIT_STATE = {
  userData: [],
};
export const logReduc = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ADD_LOGIN_DATA":
      return {

        userData: [ action.payload],
      };
    default:
      return state;
  };

};
const INIT_STATE2 = {
  verifyUserRes: [],
};
export const VerifyUser = (state = INIT_STATE2, action) => {
  switch (action.type) {
    case "VERIFY_USER_RESP":
      return {
        verifyUserRes: [action.payload],
      };
    default:
      return state;
  };

};
const INIT_STATE3 = {
  userProfileData: [],
};
export const UserProfile = (state = INIT_STATE3, action) => {
  switch (action.type) {
    case "USER_PROFILE":
      return {
        userProfileData: [ action.payload],
      };
    default:
      return state;
  };

};
// const INIT_STATE4 = {
//   Token: [],
// };
// export const UserToken = (state = INIT_STATE4, action) => {
//   switch (action.type) {
//     case "TOKEN":
//       return {
//         ...state,
//         Token: [...state.Token, action.payload],
//       };
//     default:
//       return state;
//   };
// };

const INIT_STATE5 = {
  closeSpv: [],
};
export const closeSpvData = (state = INIT_STATE5 , action) => {
  switch (action.type) {
    case "CLOSE_SPV":
      return {
        ...state,
        closeSpv: [...state.closeSpv, action.payload],
      };
    default:
      return state;
  };

};
const INIT_STATE6 = {
  activeSpv: [],
};
export const activeSpvData = (state = INIT_STATE6 , action) => {
  switch (action.type) {
    case "ACTIVE_SPV":
      return {
        ...state,
        activeSpv: [...state.activeSpv, action.payload],
      };
    default:
      return state;
  };

};
const INIT_STATE7 = {
  Kyc: [],
};
export const userKycData = (state = INIT_STATE7 , action) => {
  switch (action.type) {
    case "USER_KYC":
      return {
        ...state,
        Kyc: [...state.Kyc, action.payload],
      };
    default:
      return state;
  };

};
const INIT_STATE8 = {
  getData: [],
};
export const getUserData = (state = INIT_STATE8 , action) => {
  switch (action.type) {
    case "GET_USER":
      return {

        getData: [action.payload],
      };
    default:
      return state;
  };

};
const INIT_STATE9 = {
  PoolID: [],
};
export const CasePoolId = (state = INIT_STATE9 , action) => {
  switch (action.type) {
    case "INVEST_POOl_ID":
      return {
        // ...state,
        PoolID: [action.payload],
      };
    default:
      return state;
  };

};


