import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { IMAGE_PATH, DOMAIN_PATH } from "../constant/path";
import { Document, Page, pdfjs } from "react-pdf";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

export default function Footer() {
  return (
    <div class=" footer_bg_color">
      <div class="container_F ">
        <div className="footer flex_wrapper">
          <div class="footer_logo">
            <img className="logo_img" src={IMAGE_PATH + "Logo_nav.png"} />
            <p>
              <i class="fa fa-map-marker" aria-hidden="true"></i> Plot 250, 4th
              Floor, Phase IV, Udyog Vihar, Sector 18, Gurugram, Haryana 122015
            </p>
            <h6><span className="number_footer"><i class="fa fa-phone" aria-hidden="true"></i></span> +91-92895-54459</h6>
            <h6 className="email_footer"><i class="fa fa-envelope" aria-hidden="true"></i> care@legalpay.in</h6>
            <ul className="mediaopt">
              <li>
                <a
                  href="https://www.linkedin.com/company/legalpay"
                  target="blank"
                >
                  <img src={IMAGE_PATH + "linkedin.png"} />{" "}
                </a>
              </li>
              <li>
                <a href="https://www.twitter.com/legal_pay" target="blank">
                  <img src={IMAGE_PATH + "twitter.png"} />{" "}
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/Legalpay/" target="blank">
                  <img src={IMAGE_PATH + "insta.png"} />{" "}
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/Legalpay.in/" target="blank">
                  <img src={IMAGE_PATH + "facebook.png"} />{" "}
                </a>
              </li>
            </ul>
          </div>

          <div className="footer_column">
            <ul className="flex_wrapper">
              <li className="footer_row">
                <ul>
                  <li>Resources</li>
                  <li>
                    <Link to="/faqs">FAQs</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Insights</Link>
                  </li>
                  {/* <li>
                    <Link to="/Legalpay_whitepaper">WhitePaper</Link>
                  </li> */}
                </ul>
              </li>

              <li className="footer_row">
                <ul>
                  <li>Company</li>
                  <li>
                    <a href="/career">Careers</a>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                </ul>
              </li>

              <li className="footer_row">
                <ul>
                  <li>Explore</li>

                  <li>
                    <Link to="/privacy_policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-of-service/">Terms of Service</Link>
                  </li>
                  <li>
                    <Link to="/information-security">Information Security</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="disclamier">
          <h2>Disclaimer</h2>
          <p>
            LegalPay is not a registered broker-dealer, lender, law firm, and/or
            money transfer service and does not conduct any activity that would
            require such registration. LegalPay does not provide any kind of
            legal consultation or legal services. please read this document
            carefully before you access or use the service. by accessing,
            registering at the site, and or using LegalPay services you agree to
            be bound by the terms and conditions set forth below. if you do not
            wish to be bound by these terms and conditions, please do not access
            or register for our site and/or engage in any activity conducted by
            LegalPay. if you violate any of these terms of service (which
            include by reference LegalPay’s privacy policy), or otherwise
            violate an agreement between you and us, LegalPay may, apart from
            initiating any legal proceedings, terminate your privileges, delete
            your profile and any content or information that you have posted on
            the site and/or prohibit you from using or accessing the service or
            the site (or any portion, aspect or feature of the service or the
            site), at any time in its sole discretion, with or without notice.
            It is stated that ‘LegalPay’ and ‘LLP (created for Special Purpose
            Vehicle)’ are two separate legal entities. The Investors will become
            limited Partners to the said LLP. LLP provides case management
            services to its clients. The Investors are requested to read the
            Risk Disclosure & Consent Agreement, LLP Agreement and
            Non-Disclosure Agreement thoroughly before making the investment in
            the listed Opportunities.
          </p>
        </div>

        <div className="refund">
          <h2>Refund Policy</h2>
          <p>
            Any request for a refund can be made to investor@legalpay.in.
            LegalPay will update you on the status of your refund. The committed
            amount will be refunded to you within 7 to 14 business days of your
            request.
          </p>
        </div>
      </div>

      <div class="legalpay_private_text border_top">
        <p>© LegalPay Technology Private Limited</p>
      </div>
    </div>
  );
}
