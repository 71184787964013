import React from "react";

//Common
const PageNotFound = React.lazy(() => import("../error/PageNotFound"));
const ForbiddenAccess = React.lazy(() => import("../error/ForbiddenAccess"));

// login-SignUp
const Login = React.lazy(() => import("../pages/user/login"));
const NewUserForm = React.lazy(() => import("../pages/user/NewUserForm"));
const RecoverAccount = React.lazy(() => import("../pages/user/RecoverAccount"));

// public-Pages
const Home = React.lazy(() => import("../pages/Home"));
const GetFunding = React.lazy(() => import("../pages/GetFunding/index"));
const Investors = React.lazy(() => import("../pages/Investors/Index"));
const GetFundingForm = React.lazy(() =>
  import("../pages/GetFunding/Investor-form")
);
const About = React.lazy(() => import("../pages/About/index"));
const Career = React.lazy(() => import("../pages/career/Career"));
const Blogs = React.lazy(() => import("../pages/blogs/index"));
const FaQsPage = React.lazy(() => import("../../src/components/faq/index"));
const Article = React.lazy(() => import("../components/Article/index"));
const ArticleDescription = React.lazy(() =>
  import("../components/Article/ArticleDescription")
);
const Information_page = React.lazy(() =>
  import("../components/Information-Security/index")
);
const WhitePaper = React.lazy(() => import("../components/whitePaper"));
const PrivacyPolicy = React.lazy(() => import("../components/PrivacyPolicy"));
const TermsServices = React.lazy(() => import("../components/TermsService"));
const TermsContribution = React.lazy(() =>
  import("../components/TermsContribution")
);
const NonDisclosure = React.lazy(() => import("../components/nonDisclosure"));

const DigioKyc = React.lazy(() => import("../pages/DigioTest/index"));

// private pages
const Opportunities = React.lazy(() => import("../pages/Opportunities/index"));
const Opportunities_Details = React.lazy(() =>
  import("../pages/Opportunities/Details")
);
const InsolvencyDetails = React.lazy(() =>
  import("../pages/Opportunities/InsolvencyDetails")
);
const InvestorPortfolio = React.lazy(() =>
  import("../pages/Portfolio/Investor-Portfolio")
);
const PortfolioDetails = React.lazy(() =>
  import("../pages/Portfolio/Portfolio-Details")
);
const InvestorOpportunityList = React.lazy(() =>
  import("../pages/Portfolio/investor_opportunity_list")
);
const InvestorProfile = React.lazy(() => import("../pages/UserProfile/index"));
const InvestorKyc = React.lazy(() =>
  import("../pages/UserProfile/Investore-Kyc")
);
const ReferEarn = React.lazy(() => import("../pages/Refer-Earn/index"));
const ReferDashboard = React.lazy(() =>
  import("../pages/Refer-Earn/Refer-Dashboard")
);
const EsignAgreement = React.lazy(() =>
  import("../pages/Portfolio/esign-agreements")
);
const FAQs = React.lazy(() => import("../components/faq/FAQs_Ques"));

// const Portfolio = React.lazy(() => import('../pages/Portfolio/Investor-Portfolio'))
// const InvestorCasepoolDetails = React.lazy(() => import('../pages/Portfolio/Portfolio-Details'))
// const UserProfile = React.lazy(() => import('../pages/UserProfile/index'))
// const KycProcess = React.lazy(() => import('../../src/pages/KycCard/index'))

const configureRoutes = () => {
  const routes = [
    {
      element: <Home />,
      exact: true,
      path: "/",
      title: "Home page",
      type: "public",
    },
    {
      element: <Login />,
      exact: true,
      path: "/investor-login-n1-int/",
      title: "login page",
      type: "login",
    },
    {
      element: <NewUserForm />,
      exact: true,
      path: "/investor-register-email/",
      title: "SignUp page",
      type: "login",
    },
    {
      element: <RecoverAccount />,
      exact: true,
      path: "/account-recover/",
      title: "Account-Recover page",
      type: "login",
    },

    {
      element: <About />,
      exact: true,
      path: "/about",
      title: "About page",
      type: "public",
    },

    {
      element: <Blogs />,
      exact: true,
      path: "/blogs",
      title: "Blogs page",
      type: "public",
    },
    {
      element: <GetFunding />,
      exact: true,
      path: "/get-funding",
      title: "Get Funding",
      type: "public",
    },

    {
      element: <Investors />,
      exact: true,
      path: "/Investors/",
      title: "Investors",
      type: "public",
    },
    {
      element: <GetFundingForm />,
      exact: true,
      path: "/get-funding_investor_form",
      title: "Get Funding",
      type: "public",
    },
    {
      element: <FaQsPage />,
      exact: true,
      path: "/faqs",
      title: "FaQs_Pages",
      type: "public",
    },
    {
      element: <Career />,
      exact: true,
      path: "/career",
      title: "Career page",
      type: "public",
    },

    {
      element: <ReferEarn />,
      exact: true,
      path: "/refer-earn/",
      title: "Refer_Earn page",
      type: "private",
    },
    {
      element: <ReferDashboard />,
      exact: true,
      path: "/investor-referral-dashboard/",
      title: "Refer & Earn page",
      type: "private",
    },
    {
      element: <WhitePaper />,
      exact: true,
      path: "/Legalpay_whitepaper",
      title: "WhitePaper page",
      type: "public",
    },
    {
      element: <PrivacyPolicy />,
      exact: true,
      path: "/privacy_policy",
      title: "PrivacyPolicy page",
      type: "public",
    },
    {
      element: <TermsServices />,
      exact: true,
      path: "/terms-of-service/",
      title: "TermsServices page",
      type: "public",
    },
    {
      element: <TermsContribution />,
      exact: true,
      path: "/terms-contribution",
      title: "TermsContribution page",
      type: "public",
    },
    {
      element: <NonDisclosure />,
      exact: true,
      path: "/non_disclosure",
      title: "NonDisclosure page",
      type: "public",
    },

    {
      element: <Article />,
      exact: true,
      path: "/article",
      title: "Article page",
      type: "public",
    },
    {
      element: <ArticleDescription />,
      exact: true,
      path: "/:id/Article_Description/:id",
      title: "Article Description page",
      type: "public",
    },
    {
      element: <Information_page />,
      exact: true,
      path: "/information-security",
      title: "Article Description page",
      type: "public",
    },
    {
      element: <DigioKyc />,
      exact: true,
      path: "/DigioKyc",
      title: "DigioKyc page",
      type: "private",
    },
    // {
    //   element: <KycProcess />,
    //   exact: true,
    //   path: '/KycProcess',
    //   title: 'KycProcess page',
    //   type: 'private',
    // },
    {
      element: <Opportunities />,
      exact: true,
      path: "/investor-opportunities/",
      title: "Opportunities page",
      type: "private",
    },

    {
      element: <Opportunities_Details />,
      exact: true,
      path: "/case-pool/:id/:poolStatus/:minimum_contribution",
      title: "Opportunities Details page",
      type: "private",
    },
    {
      element: <InsolvencyDetails />,
      exact: true,
      path: "/insolvency-case-pool/:id/:poolStatus/:minimum_contribution",
      title: "Insolvency Details page",
      type: "private",
    },
    {
      element: <InvestorPortfolio />,
      exact: true,
      path: "/investor-portfolio/",
      title: "Portfolio page",
      type: "private",
    },
    {
      element: <PortfolioDetails />,
      exact: true,
      path: "/investor_portfolio_details/",
      title: "Portfolio page",
      type: "public",
    },

    {
      element: <InvestorOpportunityList />,
      exact: true,
      path: "/investor_opportunity_list/:id",
      title: "investor_opportunity_list page",
      type: "private",
    },

    // {
    //   element: <InvestorCasepoolDetails />,
    //   exact: true,
    //   path: '/Investor-case-pool-Details/:id',
    //   title: 'Investor CasePool Details page',
    //   type: 'private',
    // },
    {
      element: <EsignAgreement />,
      exact: true,
      path: "/pending-esign-agreements",
      title: "esign agreements page",
      type: "private",
    },
    {
      element: <InvestorProfile />,
      exact: true,
      path: "/investor-profile/",
      title: "UserProfile page",
      type: "private",
    },
    {
      element: <InvestorKyc />,
      exact: true,
      path: "/investor-profile/profile-kyc/:type",
      title: "InvestorKyc page",
      type: "private",
    },

    {
      element: <FAQs />,
      exact: true,
      path: "/investor-faqs",
      title: "FAQs page",
      type: "private",
    },

    {
      element: <ForbiddenAccess />,
      exact: true,
      path: "/forbidden-access",
      title: "403 Forbidden Access",
      type: "error",
    },
    {
      element: <PageNotFound />,
      exact: true,
      path: "*",
      title: "404 Not Found",
      type: "public",
    },
  ];

  return routes;
};

export default configureRoutes;
