import React from "react";
import { useEffect, useState } from "react";
import { NewOpportunity } from "../services/api/api.service";
import { Link } from "react-router-dom";

export default function LatestOpportunity() {
  const [opportunities, setOpportunityes] = useState();

  useEffect(() => {
    NewOpportunity()
      .then((res) => {
        setOpportunityes(res.data);
        console.warn("notification", res);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  return (
    <>
      {opportunities?.map((item) => {
        return (
          <>
            {item.status == true ? (
              <div className="top_head">
                <h2 className="target">
                  <a href={item.link} target="blank">
                    {item.text_line}
                  </a>
                </h2>
              </div>
            ) : null}
          </>
        );
      })}
    </>
  );
}
