export const TOKEN =(item)=>{
    return{
        type:"TOKEN",
        payload:item
    }
}
export const ADD_LOGIN_DATA=(item)=>{
    return{
        type:"ADD_LOGIN_DATA",
        payload:item
    }
}
export const VERIFY_USER_RESP=(item)=>{
    return{
        type:"VERIFY_USER_RESP",
        payload:item
    }
}
export const USER_PROFILE=(item)=>{
    return{
        type:"USER_PROFILE",
        payload:item
    }
}
export const CLOSE_SPV=(item)=>{
    return{
        type:"CLOSE_SPV",
        payload:item
    }
}
export const ACTIVE_SPV=(item)=>{
    return{
        type:"ACTIVE_SPV",
        payload:item
    }
}
export const GET_USER=(item)=>{
    return{
        type:"GET_USER",
        payload:item
    }
}

export const USER_KYC=(item)=>{
    return{
        type:"USER_KYC",
        payload:item
    }
}
export const INVEST_POOl_ID=(item)=>{
    return{
        type:"INVEST_POOl_ID",
        payload:item
    }
}
