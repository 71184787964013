import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import SuspenseLoader from "./common/SuspenseLoader";
// import reportWebVitals from "./reportWebVitals";
import { createBrowserHistory } from "history";
import ReactScroll from "react-scroll";
import store, { persistor } from "./Store";
import { Provider } from "react-redux";
import ErrorBoundary from "./error/Error-Boundary";
import { PersistGate } from "redux-persist/integration/react";
import ReactGA from "react-ga4";

let NODE_ENV = "production";
if (NODE_ENV !== "development") console.log = () => {};

let prevLocation = {};

if (process.env.REACT_APP_ENV === "prod") {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

// history.listen((location) => {
//   const pathChanged = prevLocation?.pathname !== location.pathname;
//   const hashChanged = prevLocation?.has !== location.hash;

//   if (pathChanged || hashChanged) {
//     ReactScroll.animateScroll.scrollToTop();
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//       /* you can also use 'auto' behaviour
//          in place of 'smooth' */
//     });
//   }

//   prevLocation = location;
// });

ReactGA.initialize("G-X9V74ZZZZS");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={<SuspenseLoader />} persistor={persistor}>
      <ErrorBoundary>
        <React.Suspense fallback={<SuspenseLoader />}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </React.Suspense>
      </ErrorBoundary>
    </PersistGate>
  </Provider>
);

// reportWebVitals();
