import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
export default function BottomMenubar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const navigate = useNavigate();

  const logOut = () => {
    navigate("/");
    localStorage.clear();
    window.location.reload();
  };
  useEffect(() => {
    ReactGA.event("bottomMenubar",{action: 'BottomMenubar'})
    if (click) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [click]);

  return (
    <>
    <div className="main_div_bottomrefer">
      <ul
        className={click ? "nav-menu active" : "nav-menu-bottom"}
        style={{ zIndex: 100 }}
      >
        <li className="nav-item">
          <NavLink
            exact
            to="/investor-opportunities/"
            activeClassName="active"
            className="nav-links"
            onClick={handleClick}
          >
            Opportunities
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            exact
            to="/investor-portfolio/"
            activeClassName="active"
            className="nav-links"
            onClick={handleClick}
          >
            Portfolio
          </NavLink>
        </li>
        <li className="nav-item  refer_navbtn">
          <NavLink
            exact
            to="/refer-earn/"
            activeClassName="active"
            className="nav-links"
            onClick={handleClick}
          >
            Refer & Earn
          </NavLink>
        </li>

        {/* 
            <li className="nav-item " id="dropdownMenu">
              <NavLink
                exact
                to="/investor-profile/"
                activeClassName="active"
                className="nav-links dropdown_hide"
                onClick={(e) => {
                  setShowMore(!ShowMore);
                  e.preventDefault();
                }}
              >
              
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </NavLink>
              <div id="MoreOpt">
                <ul>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/investor-profile/"
                      activeClassName="active"
                      className="nav-links"
                    >
                      Profile
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/investor-faqs"
                      activeClassName="active"
                      className="nav-links "
                      onClick={handleClick}
                    >
                      FAQs
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/"
                      activeClassName="active"
                      className="nav-links"
                      onClick={() => {
                        logOut();
                      }}
                    >
                      Logout
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li> */}
        <li className="nav-item">
          <NavLink
            exact
            to="/investor-profile/"
            activeClassName="active"
            className="nav-links hide_profile"
            onClick={handleClick}
          >
            Profile
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            exact
            to="/investor-faqs"
            activeClassName="active"
            className="nav-links hide_profile"
            onClick={handleClick}
          >
            FAQs
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            exact
            to="/dummy"
            activeClassName="active"
            className="nav-links hide_profile"
            onClick={() => {
              logOut();
            }}
          >
            Log Out
          </NavLink>
        </li>
      </ul>
      </div>
      <div className="main_bottom_menu">
        <ul className="flex_wrapper">
          <li>
            <a href="/investor-opportunities/">
              <i class="fa fa-home" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href="/investor-portfolio/">
              <i class="fa fa-folder-open" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href=" /investor-profile/">
              <i class="fa fa-user" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href="/investor-faqs">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href="#" onClick={handleClick}>
              {click ? (
                <i class="fa fa-times" aria-hidden="true"></i>
              ) : (
                <i class="fa fa-bars" aria-hidden="true"></i>
              )}
            </a>
          </li>
        </ul>
      </div>

      {/* <div className="nav-icon" onClick={handleClick}>
            {click ? (
              <i class="fa fa-times" aria-hidden="true"></i>
            ) : (
              <i class="fa fa-bars" aria-hidden="true"></i>
            )}
          </div> */}
    </>
  );
}
