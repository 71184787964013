import React from "react";
import Header from "../common/header";
import Footer from "../common/footer";
import NewOpportunity from "../common/newOpportunity";

const PublicRoute = ({ ...rest }) => {
  return (
    <>
    <NewOpportunity/>
      <Header />
      {rest.element}
      <Footer />
    </>
  );
};
export default PublicRoute;
