export const DOMAIN_PATH =
  process.env.REACT_APP_ENV === "prod"
    ? "https://investor.legalpay.in"
    : "https://investor.legalpay.in";
export const IMAGE_PATH =
  process.env.REACT_APP_ENV === "prod"
    ? "https://investor.legalpay.in/images/"
    : "https://test.legalpay.in/images/";
export const ADMIN_API_PATH =
  process.env.REACT_APP_ENV === "prod"
    ? "https://api.legalpay.in"
    : "https://sandbox.legalpay.in";
export const ADMIN_API_PATH_BUSSINESS = "http://3.7.180.225:8000";
export const SOCIAL_URL = "https://investor.legalpay.in/";
export const logo =
  "https://investor.legalpay.in/wp-content/uploads/2021/05/cropped-legalpay-logo.png";
